/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

const abcColors = ['#DE3C3F', '#EC8305', '#046FEA'];

export const ABCNumber = ({items}: {items: SafeAny}) => {
  const {calcActualSize} = useScreenSize();
  const {number} = items;
  return (
    <View
      style={[
        theme.flex.flex,
        theme.flex.row,
        {
          gap: calcActualSize(4),
        },
      ]}>
      {number.split('').map((item: string, index: number) =>
        item !== '-' ? (
          <View
            style={[
              theme.flex.flex,
              theme.flex.center,
              {
                width: calcActualSize(24),
                height: calcActualSize(24),
                borderRadius: calcActualSize(24),
                backgroundColor: abcColors[index],
              },
            ]}
            key={index}>
            <Text
              calc
              color={theme.basicColor.white}
              fontWeight="700"
              fontSize={12}>
              {item}
            </Text>
          </View>
        ) : null,
      )}
    </View>
  );
};
