/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View, Image, ImageBackground} from 'react-native';
import {DICES} from './constant';
import Text from '@/components/basic/text';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface DiceItemProps {
  diceSize?: number;
  diceNum?: number | string;
  disabled?: boolean;
}
const DiceItem = ({
  diceSize = 10,
  diceNum = 1,
  disabled = false,
}: DiceItemProps) => {
  const {calcActualSize} = useScreenSize();
  return (
    <Image
      source={DICES[disabled ? `${diceNum}-disabled` : diceNum]}
      style={{
        width: calcActualSize(diceSize),
        height: calcActualSize(diceSize),
      }}
    />
  );
};

interface BallProps {
  ballSize?: number;
  isNum?: boolean;
  num?: number;
  fontSize?: number;
  type?: string;
  disabled?: boolean;
}
const Ball = React.memo(
  ({
    ballSize,
    isNum = true,
    num = 3,
    fontSize = 28,
    type = '',
    disabled = false,
  }: BallProps) => {
    const textColor = React.useMemo(() => {
      return disabled ? '#666' : num % 2 === 0 ? '#00701C' : '#CE0006';
    }, [num, disabled]);

    const bg = React.useMemo(() => {
      if (isNum) {
        return num % 2 === 0
          ? DICES[disabled ? 'greenBall-disabled' : 'greenBall']
          : DICES[disabled ? 'redBall-disabled' : 'redBall'];
      } else {
        return DICES[disabled ? `${type}-disabled` : type];
      }
    }, [isNum, num, type, disabled]);

    return (
      <ImageBackground
        source={bg}
        style={[
          theme.flex.center,
          {
            width: ballSize,
            height: ballSize,
          },
        ]}>
        {isNum && (
          <Text color={textColor} fontSize={fontSize} fontFamily="fontDinBold">
            {num}
          </Text>
        )}
      </ImageBackground>
    );
  },
);

export const NumberNode = ({item}: {item: SafeAny}) => {
  const {calcActualSize} = useScreenSize();
  const toNumber = Number(item.number);
  let numberArr = [];
  switch (item.playType.toLowerCase()) {
    case 'single':
      return <DiceItem diceSize={28} diceNum={toNumber} />;
    case 'sum':
      return (
        <Ball
          ballSize={28}
          num={toNumber}
          fontSize={16}
          isNum={!isNaN(toNumber)}
          type={item?.number?.toLowerCase()}
        />
      );
    case 'double':
    case 'pair':
      numberArr = Array(2).fill(
        item.number.length > 1 ? item.number[0] : item.number,
      );
      return (
        <View style={[theme.flex.row]}>
          {numberArr.map((pie: string, i: number) => (
            <DiceItem key={i} diceSize={20} diceNum={Number(pie)} />
          ))}
        </View>
      );
    case 'leopard':
    case 'Triple':
      if (isNaN(toNumber)) {
        return (
          <View style={[theme.flex.row]}>
            <View style={[theme.flex.centerByCol]}>
              <Image
                source={DICES.any}
                style={{width: calcActualSize(20), height: calcActualSize(20)}}
              />
              <View style={[theme.flex.row]}>
                {['any', 'any'].map((pie: string, i: number) => (
                  <Image
                    key={i}
                    source={DICES[pie]}
                    style={{
                      width: calcActualSize(20),
                      height: calcActualSize(20),
                    }}
                  />
                ))}
              </View>
            </View>
          </View>
        );
      } else {
        numberArr = Array(3).fill(
          item.number.length > 1 ? item.number[0] : item.number,
        );
        return (
          <View style={[theme.flex.row]}>
            <View style={[theme.flex.centerByCol]}>
              <DiceItem diceSize={20} diceNum={Number(numberArr[0])} />
              <View style={[theme.flex.row]}>
                {numberArr.slice(1).map((pie: string, i: number) => (
                  <DiceItem key={i} diceNum={Number(pie)} diceSize={20} />
                ))}
              </View>
            </View>
          </View>
        );
      }
    default:
      return <></>;
  }
};
export const getGameType = (item: SafeAny) => {
  const map = {
    single: 'Single',
    Single: 'Single',
    sum: 'Sum',
    Sum: 'Sum',
    double: 'Pair',
    Pair: 'Pair',
    leopard: 'Triple',
    Triple: 'Triple',
  };
  return (
    <Text calc second fontWeight="500" textAlign="center">
      {(map as any)[item.playType]}
    </Text>
  );
};
