/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export const MatkaNumber = ({items}: {items: SafeAny}) => {
  const {calcActualSize} = useScreenSize();
  const {number, digits} = items;
  return (
    <View
      style={[
        theme.flex.flex,
        theme.flex.col,
        theme.flex.alignStart,
        {gap: calcActualSize(2)},
      ]}>
      <Text
        calc
        color={theme.fontColor.main}
        fontSize={14}
        fontWeight="500"
        textAlign="center">
        {number}
      </Text>
      <Text
        calc
        color={theme.fontColor.main}
        fontSize={14}
        fontWeight="700"
        textAlign="center">
        {digits}
      </Text>
    </View>
  );
};
