/* eslint-disable react-native/no-inline-styles */
import {BetsSharBackgroundColor, Logo} from './bets-shard.variable';
import {View} from 'react-native';
import Text from '@basicComponents/text';
import {
  basicColor,
  flex,
  fontColor,
  fontSize,
  margin,
} from '@/components/style';
import React from 'react';
import LazyImage from '@/components/basic/image';
import {SafeAny} from '@/types';
import dayjs from 'dayjs';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import theme from '@/style';
import {toPriceStr} from '@/utils';

const BetsShardInfo = ({
  backgroundColor = '#FFECAB',
  borderTopRadius = 0,
  borderBottomRadius,
  result = (
    <View style={[flex.flex, flex.centerByCol, flex.between, flex.row]}>
      <Text
        calc
        color={basicColor.dark}
        fontSize={fontSize.s}
        blod
        fontFamily="fontInter">
        Won
      </Text>
      <Text
        calc
        style={[margin.leftxxs]}
        color={basicColor.primary}
        fontSize={14}
        blod
        fontFamily="fontDin">
        ₹150.0
      </Text>
    </View>
  ),
  orderInfo,
  top = false,
}: SafeAny) => {
  const {calcActualSize} = useScreenSize();
  const renderDotted = () => {
    const width = calcActualSize(336 / 8);
    const dottes = [];
    for (let i = 0; i < width; i++) {
      dottes.push(i);
    }
    return (
      <View
        style={[
          theme.flex.flex,
          theme.flex.row,
          theme.position.abs,
          {
            overflow: 'hidden',
            gap: calcActualSize(4),
            left: calcActualSize(0),
          },
          top
            ? {
                top: 0,
              }
            : {
                bottom: 0,
              },
        ]}>
        {dottes.map((_, index) => (
          <View
            key={index}
            style={[
              {
                height: 1,
                width: calcActualSize(4),
                backgroundColor: top ? '#815268' : '#9fabb9',
              },
            ]}
          />
        ))}
      </View>
    );
  };
  const showName = () => {
    let pre = '';
    if (orderInfo?.gameType === 'scratch') {
      pre = 'Scratch off-';
    }
    if (orderInfo?.gameType === 'stateLottery') {
      pre = 'StateLottery-';
    }
    return pre + orderInfo?.gameName;
  };
  return (
    <View
      style={[
        theme.flex.flex,
        theme.flex.col,
        theme.position.rel,
        {
          width: '100%',
          // height: 208,
          backgroundColor: backgroundColor,
          borderTopLeftRadius: calcActualSize(borderTopRadius),
          borderTopRightRadius: calcActualSize(borderTopRadius),
          borderBottomLeftRadius: borderBottomRadius ?? calcActualSize(12),
          borderBottomRightRadius: borderBottomRadius ?? calcActualSize(12),
          padding: calcActualSize(12),
          gap: calcActualSize(12),
          overflow: 'hidden',
        },
      ]}>
      {renderDotted()}
      <View
        style={[
          flex.flex,
          flex.centerByCol,
          flex.between,
          flex.row,
          {
            gap: calcActualSize(12),
          },
        ]}>
        {['line', '', 'line'].map((item, index) => (
          <View
            key={index}
            style={
              item === 'line'
                ? [
                    {width: calcActualSize(94), height: calcActualSize(2)},
                    BetsSharBackgroundColor,
                  ]
                : [flex.flex, flex.centerByCol, flex.row]
            }>
            {item !== 'line' && (
              <>
                <View
                  style={[
                    {
                      width: calcActualSize(32),
                      height: calcActualSize(32),
                      overflow: 'hidden',
                      borderRadius: calcActualSize(theme.paddingSize.s),
                      marginRight: calcActualSize(4),
                    },
                  ]}>
                  <LazyImage
                    imageUrl={Logo}
                    occupancy="#0000"
                    width={calcActualSize(32)}
                    height={calcActualSize(32)}
                  />
                </View>
                {/* <LazyImage
                  imageUrl={SINGAM}
                  occupancy="#0000"
                  width={calcActualSize(68)}
                  height={calcActualSize(14)}
                /> */}
                <Text fontSize={15} calc fontFamily="fontDin" fontWeight="900">
                  BHAU
                </Text>
              </>
            )}
          </View>
        ))}
      </View>
      <View style={[flex.flex, flex.centerByCol, flex.between, flex.row]}>
        <View
          style={[
            flex.row,
            flex.centerByCol,
            {
              gap: calcActualSize(theme.paddingSize.l),
            },
          ]}>
          <View
            style={[
              {
                width: calcActualSize(48),
                height: calcActualSize(48),
                overflow: 'hidden',
                borderRadius: calcActualSize(theme.paddingSize.s),
              },
            ]}>
            <LazyImage
              imageUrl={orderInfo?.gameIconUrl}
              occupancy="#0000"
              width={calcActualSize(48)}
              height={calcActualSize(48)}
            />
          </View>
          <View>
            <View>
              <Text
                calc
                color={fontColor.main}
                fontSize={fontSize.m}
                blod
                fontFamily="fontInter">
                {showName()}
              </Text>
            </View>
            <View
              style={[
                flex.row,
                {
                  marginTop: calcActualSize(4),
                },
              ]}>
              <Text
                calc
                color={fontColor.accent}
                fontSize={fontSize.s}
                fontFamily="fontInter">
                Draw time
              </Text>
              <Text
                calc
                style={[margin.lefts]}
                color={basicColor.dark}
                fontSize={fontSize.s}
                fontFamily="fontInter">
                {dayjs(orderInfo?.openTime).format('DD-MM hh:mm A')}
              </Text>
            </View>
          </View>
        </View>
        <View>
          <Text
            calc
            color={fontColor.accent}
            fontSize={fontSize.s}
            fontFamily="fontInter">
            Payment
          </Text>
          <Text
            calc
            color={fontColor.main}
            fontSize={fontSize.m}
            blod
            fontFamily="fontInter"
            style={[
              margin.topxxs,
              {
                textAlign: 'right',
              },
            ]}>
            {toPriceStr(orderInfo?.totalAmount)}
          </Text>
        </View>
      </View>
      <View
        style={[theme.flex.flex, theme.flex.col, {gap: calcActualSize(12)}]}>
        <View
          style={[
            {
              width: '100%',
              height: 1,
              backgroundColor: '#d8b892',
            },
          ]}
        />
        <View style={[flex.flex, flex.centerByCol, flex.between, flex.row]}>
          <Text
            calc
            fontSize={fontSize.s}
            fontFamily="fontInter"
            color={fontColor.accent}>
            Betting time
          </Text>
          <Text
            calc
            fontSize={fontSize.s}
            fontFamily="fontInter"
            color={basicColor.dark}>
            {dayjs(orderInfo?.createTime).format('DD-MM-YYYY hh:mm A')}
          </Text>
        </View>
        <View style={[flex.flex, flex.centerByCol, flex.between, flex.row]}>
          <Text
            calc
            fontSize={fontSize.s}
            color={fontColor.accent}
            fontFamily="fontInter">
            Result
          </Text>
          {result}
        </View>
      </View>
    </View>
  );
};
export default BetsShardInfo;
