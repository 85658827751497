/* eslint-disable react-native/no-inline-styles */
import theme from '@/style';
import React from 'react';
import {ImageBackground} from 'react-native';
import {BALLS} from './constant';
import Text from '@/components/basic/text';
import {SafeAny} from '@/types';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

interface BallProps {
  digit: string;
  disabled?: boolean;
  ballSize?: number;
  fontSize?: number;
  color?: string;
  onlyColor: boolean;
  fontColor?: string;
}
const Ball = ({
  ballSize = 56,
  digit = '0',
  disabled = false,
  color,
  onlyColor = false,
  fontColor,
  fontSize = 24,
}: BallProps) => {
  const {calcActualSize} = useScreenSize();
  const bg = React.useMemo(() => {
    if (onlyColor) {
      if (color === 'g') {
        return BALLS.green;
      }
      if (color === 'v') {
        return BALLS.violet;
      }
      if (color === 'r') {
        return BALLS.red;
      }
    } else {
      if (color === 'g') {
        return disabled ? BALLS.colorGreenDisabled : BALLS.colorGreen;
      }
      if (color === 'r') {
        return disabled ? BALLS.colorRedDisabled : BALLS.colorRed;
      }
      if (color === 'g,v') {
        return disabled ? BALLS.colorGreenVDisabled : BALLS.colorGreenV;
      }
      if (color === 'r,v') {
        return disabled ? BALLS.colorRedVDisabled : BALLS.colorRedV;
      }
      if (color === '*') {
        return BALLS.colorNone;
      }
    }
  }, [disabled, color, onlyColor]);

  const num = React.useMemo(() => {
    if (onlyColor) {
      return digit === 'g' ? 'GREEN' : digit === 'r' ? 'RED' : 'VIOLET';
    } else {
      return digit;
    }
  }, [onlyColor, digit]);

  return (
    <ImageBackground
      style={[
        {
          width: calcActualSize(ballSize),
          height: calcActualSize(ballSize),
        },
        theme.flex.center,
      ]}
      source={bg}>
      <Text
        calc
        fontFamily="fontInterBold"
        fontSize={fontSize}
        color={fontColor || theme.fontColor.second}>
        {num}
      </Text>
    </ImageBackground>
  );
};

export const ColorNumber = ({item}: {item: SafeAny}) => {
  const {selectType, selectValue} = item;
  const bg = React.useMemo(() => {
    if (selectType === 2) {
      return selectValue;
    } else {
      if (['2', '4', '6', '8'].includes(selectValue)) {
        return 'r';
      } else if (['1', '3', '7', '9'].includes(selectValue)) {
        return 'g';
      } else if (selectValue === '0') {
        return 'r,v';
      } else {
        return 'g,v';
      }
    }
  }, [selectValue, selectType]);
  return (
    <Ball
      color={bg}
      fontColor={
        selectType === 2 ? theme.basicColor.white : theme.basicColor.dark
      }
      digit={selectValue}
      ballSize={28}
      fontSize={selectType === 2 ? 8 : 12}
      onlyColor={selectType === 2}
    />
  );
};
