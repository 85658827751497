import DetailCard from '@/components/business/bets/detail-card';
import theme from '@/style';
import {BasicObject} from '@/types';
import dayjs from 'dayjs';
import React from 'react';
import {View} from 'react-native';
import Text from '@/components/basic/text';
import {goTo, toPriceStr} from '@/utils';
import {useTranslation} from 'react-i18next';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import globalStore from '@/services/global.state';
import {LotteryOrderTicket} from '../mix-lottery/mix-lottery-service';
import {homePage} from '@/config';

const BetsDetailCard = ({
  info,
  game,
  onCopy,
}: {
  info: BasicObject;
  game: string;
  onCopy?: (v: string) => void;
}) => {
  const {i18n} = useTranslation();
  const itemContent = (
    drawTime: string | number,
    orderAmount: number,
    orderNumber: number,
    source?: string,
  ) => {
    if (drawTime) {
      const time =
        typeof drawTime === 'string'
          ? drawTime
          : dayjs(
              `${drawTime}`.length === 13 ? drawTime : drawTime * 1000,
            ).format('DD-MM hh:mm A');
      return (
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text color={theme.fontColor.accent} style={[theme.margin.rights]}>
            {i18n.t('bets-page.label.drawTime')}
          </Text>
          <Text color={theme.fontColor.second}>{time}</Text>
        </View>
      );
    }
    if (source) {
      return source === 'SKY GAME' ? '' : <Text>{source}</Text>;
    }
    return (
      <Text
        color={theme.basicColor.dark}
        fontFamily="fontDinBold">{`${toPriceStr(orderAmount, {
        fixed: 2,
        showCurrency: true,
        thousands: true,
      })} x ${orderNumber || 1}=${toPriceStr(
        orderAmount * (orderNumber || 1),
      )}`}</Text>
    );
  };

  const status = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    if (gameCode === 'kerala') {
      // Kerala
      if (info.bonusStatus === 0) {
        return info.wonAmount > 0 ? 1 : 0;
      }
      return info.bonusStatus;
    }
    if (gameCode === 'pick3' || gameCode === 'quick 3digit') {
      // 3 Digit
      if (info.wonCode && !info.wonCode.startsWith('*')) {
        // 已开奖
        return info.winAmount > 0 ? 1 : 0;
      }
      return 2;
    }
    if (gameCode === 'color') {
      // color
      if (info.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return info.awardAmount > 0 ? 1 : 0;
      }
    }
    if (gameCode === 'matka') {
      if (info.openStatus === 1) {
        return 2;
      }
      return info.wonAmount > 0 ? 1 : 0;
    }
    if (gameCode === 'dice') {
      // dice
      if (info.openStatus === 1) {
        // 未开奖
        return 2;
      } else {
        return info.totalAwardAmount > 0 ? 1 : 0;
      }
    }
    if (gameCode === 'lottery official india') {
      if (!info.wonCode.every((v: string) => v.indexOf('-') === -1)) {
        // 未开奖
        return 2;
      } else {
        if (
          game === 'Quick State Lottery' ||
          info.ticketsLists.every(
            (v: LotteryOrderTicket) => v.ticketStatus === 2,
          )
        ) {
          return info.totalPrize > 0 ? 4 : 0;
        }
        return 3;
      }
    }
    if (info.orderStatus !== undefined && info.openStatus !== undefined) {
      // 1=中奖 0=未中奖 2=未使用
      // scratch
      if (info.openStatus === 2) {
        // 未开奖
        return 2;
      }
      return info.orderStatus;
    }
    return info.orderStatus;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  const drawTime =
    info.drawDate ||
    info.gameDrawTime ||
    info.openTime ||
    info.drawTime ||
    info.drawSec;

  const id = info.orderGroup || info.orderId || info.issueNo || info.issNo;

  const time = React.useMemo(() => {
    const target = info.createTime || info.orderTime;
    if (target) {
      if (typeof target === 'string') {
        return target;
      }
      return dayjs(`${target}`.length === 13 ? target : target * 1000).format(
        'DD/MM/YYYY hh:mm A',
      );
    }
  }, [info]);

  const goPlay = () => {
    if (!globalStore.token) {
      goTo('Login', {backPage: homePage});
      return;
    }
    goTo('Scratch', {
      path: `my-scratch-list/detail/${info.orderId}`,
      hideInnerTitle: '1',
    });
  };

  const rightEle = (information: BasicObject = {}) => {
    if (information!.orderOverNum > 0) {
      return (
        <NativeTouchableOpacity
          onPress={goPlay}
          style={[
            theme.padding.lrs,
            theme.background.primary,
            theme.borderRadius.xs,
            theme.flex.center,
          ]}>
          <Text
            fontSize={10}
            fontFamily="fontInterBold"
            color={theme.basicColor.white}>
            ({information!.orderOverNum}/{information!.orderNumber})
          </Text>
          <Text fontFamily="fontInterBold" color={theme.basicColor.white}>
            {i18n.t('bets-page.label.playNow')}
          </Text>
        </NativeTouchableOpacity>
      );
    }
    return null;
  };

  const reward =
    info.winAmount ||
    info.awardAmount ||
    info.wonAmount ||
    info.orderWinAmount ||
    info.totalAwardAmount ||
    info.totalPrize;

  const gameType = React.useMemo(() => {
    const {shareGameDto = {}} = info || {};
    const {gameCode} = shareGameDto;
    const normal = [
      'matka',
      'dice',
      'color',
      'quick 3digit',
      'pick3',
      'kerala',
    ];
    if (gameCode === 'scratch') {
      return 2;
    }
    if (normal.includes(gameCode)) {
      return 1;
    }
    return 3;
  }, [info]);

  const resultText = React.useMemo(() => {
    const obj = {
      0: 'noWin',
      1: 'won',
      2: 'noDraw',
      3: 'settling',
      4: 'settled',
    } as BasicObject;
    if (gameType === 3) {
      if (status === 4) {
        return info.totalPrize > 0
          ? i18n.t(`bets-detail.label.${obj[1]}`)
          : i18n.t(`bets-detail.label.${obj[0]}`);
      }
    }
    return i18n.t(`bets-detail.label.${obj[status]}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, gameType, info]);

  const name = React.useMemo(() => {
    if (game === 'Quick 3D') {
      return `3 Digit-${info.gameName}`;
    }
    if (game === 'State Lottery') {
      return `${info.lotteryName} No.${info.roundNo}`;
    }
    if (game === 'Quick State Lottery') {
      return `${info.lotteryName}-${info.tabMin}min`;
    }
    return info.gameName || info.issueNo;
  }, [info, game]);

  return (
    <View
      style={[
        theme.background.white,
        theme.padding.l,
        theme.margin.btml,
        theme.borderRadius.m,
      ]}>
      <DetailCard
        hasResult
        awardNumber={reward}
        result={resultText}
        id={id}
        rightEle={gameType === 2 ? rightEle(info) : null}
        amount={
          info.totalAmount ||
          info.amount ||
          (info.orderAmount && info.orderAmount * (info.orderNumber || 1))
        }
        onCopy={() => onCopy?.(id)}
        betTime={time}
        status={status}
        tip={itemContent(
          drawTime,
          info.orderAmount,
          info.orderNumber,
          info.source,
        )}
        name={name}
        gameLogo={info.gameIconUrl || info.gameIcon || info.icon}
      />
    </View>
  );
};

export default BetsDetailCard;
