import {BasicObject} from '@/types';

const red = require('@components/assets/imgs/game/color/color-red.webp');
const green = require('@components/assets/imgs/game/color/color-green.webp');
const greenV = require('@components/assets/imgs/game/color/color-green-violet.webp');
const redV = require('@components/assets/imgs/game/color/color-red-violet.webp');
const violet = require('@components/assets/imgs/game/color/color-violet.webp');
const colorRed = require('@components/assets/imgs/game/color/red.webp');
const colorRedDisabled = require('@components/assets/imgs/game/color/red-disabled.webp');
const colorGreen = require('@components/assets/imgs/game/color/green.webp');
const colorGreenDisabled = require('@components/assets/imgs/game/color/green-disabled.webp');
const colorRedV = require('@components/assets/imgs/game/color/red-violet.webp');
const colorRedVDisabled = require('@components/assets/imgs/game/color/red-violet-disabled.webp');
const colorGreenV = require('@components/assets/imgs/game/color/green-violet.webp');
const colorGreenVDisabled = require('@components/assets/imgs/game/color/green-violet-disabled.webp');
const colorNone = require('@components/assets/imgs/game/color/color-none.webp');

const DICES = {
  1: require('@components/assets/imgs/game/dice/1-red.webp'),
  '1-disabled': require('@components/assets/imgs/game/dice/1-red-disabled.webp'),
  2: require('@components/assets/imgs/game/dice/2-black.webp'),
  '2-disabled': require('@components/assets/imgs/game/dice/2-black-disabled.webp'),
  3: require('@components/assets/imgs/game/dice/3-black.webp'),
  '3-disabled': require('@components/assets/imgs/game/dice/3-black-disabled.webp'),
  4: require('@components/assets/imgs/game/dice/4-red.webp'),
  '4-disabled': require('@components/assets/imgs/game/dice/4-red-disabled.webp'),
  5: require('@components/assets/imgs/game/dice/5-black.webp'),
  '5-disabled': require('@components/assets/imgs/game/dice/5-black-disabled.webp'),
  6: require('@components/assets/imgs/game/dice/6-black.webp'),
  '6-disabled': require('@components/assets/imgs/game/dice/6-black-disabled.webp'),
  ball: require('@components/assets/imgs/game/dice/ball.webp'),
  redBall: require('@components/assets/imgs/game/dice/red.webp'),
  'redBall-disabled': require('@components/assets/imgs/game/dice/red-disabled.webp'),
  greenBall: require('@components/assets/imgs/game/dice/green.webp'),
  'greenBall-disabled': require('@components/assets/imgs/game/dice/green-disabled.webp'),
  small: require('@components/assets/imgs/game/dice/small.webp'),
  'small-disabled': require('@components/assets/imgs/game/dice/small-disabled.webp'),
  big: require('@components/assets/imgs/game/dice/big.webp'),
  'big-disabled': require('@components/assets/imgs/game/dice/big-disabled.webp'),
  even: require('@components/assets/imgs/game/dice/even.webp'),
  'even-disabled': require('@components/assets/imgs/game/dice/even-disabled.webp'),
  odd: require('@components/assets/imgs/game/dice/odd.webp'),
  'odd-disabled': require('@components/assets/imgs/game/dice/odd-disabled.webp'),
  any: require('@components/assets/imgs/game/dice/dice-question.webp'),
} as BasicObject;

const COLOR_MAP = {
  1: '#E20000',
  0: '#02921B',
  2: '#0090E2',
} as BasicObject;

const RESULT_MAP = {
  1: 'B',
  0: 'T',
  2: 'S',
} as BasicObject;

export const SUF_NUM = [
  'zero',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
];

const SUF_TYPE = ['big', 'small', 'odd', 'even'];

const SUM_POINTS = SUF_NUM.slice(3).map(item => `sum_${item}`);

const SINGLE = SUF_NUM.slice(1, 7);

const BALLS = {
  red,
  green,
  greenV,
  redV,
  violet,
  colorRed,
  colorGreen,
  colorRedV,
  colorGreenV,
  colorRedDisabled,
  colorGreenDisabled,
  colorRedVDisabled,
  colorGreenVDisabled,
  colorNone,
};

export const COLORS = {
  A: '#D57300',
  B: '#0087D4',
  C: '#D50000',
  D: '#67A519',
  UNUSED: '#5F6975',
} as BasicObject;

export const RESULT_INDEX = ['UNUSED', 'UNUSED', 'A', 'B', 'C', 'D'] as [
  'UNUSED',
  'UNUSED',
  'A',
  'B',
  'C',
  'D',
];

export {DICES, SUF_TYPE, SUM_POINTS, SINGLE, COLOR_MAP, RESULT_MAP, BALLS};
